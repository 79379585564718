import React from 'react';

import Layout from '@common/Layout';

import Footer from "../components/sections/Footer";
import { Container, HeaderMainText, Text } from '@components/global';
import {Brand, StyledContainer} from "../components/common/Navbar/style";

import { ReactComponent as BrandIcon } from '@static/logo.svg'

import {Link} from "gatsby";

const NotFoundPage = () => (
  <Layout>
      <StyledContainer>
          <Brand>
              <Link to="/">
                  <BrandIcon />
              </Link>
          </Brand>
      </StyledContainer>
      <Container>
          <Text>
              <HeaderMainText>Sorry, File not found. <Link to="/">Go to Home page</Link></HeaderMainText>
          </Text>
      </Container>
      <Footer/>
  </Layout>
);

export default NotFoundPage;
